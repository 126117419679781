<template>
  <div>
    <a-modal v-model="visible" :title="$t('退库')" :destroyOnClose="true" @ok="confirm" @cancel="cancel">
      <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 4, md: 6 }" :wrapper-col="{ span: 16, md: 18 }">
        <a-form-model-item prop="location" :label="$t('库位')">
          <location-select v-model="form.location" :warehouse="this.warehouse" />
        </a-form-model-item>
        <a-form-model-item prop="return_quantity" :label="$t('退库数量')">
          <a-input-number v-model="form.return_quantity" style="width: 100%" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { stockOutReturnRecordCreate } from "@/api/stockOut";

export default {
  components: {
    LocationSelect: () => import("@/components/LocationSelect"),
  },
  props: ["visible", "deliveryMaterial", "warehouse"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      form: {},
      rules: {
        location: [{ required: true, message: this.$t("请选择库位"), trigger: "change" }],
        return_quantity: [{ required: true, message: this.$t("请输入退库数量"), trigger: "change" }],
      },
      loading: false,
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let formData = { ...this.form };

          this.loading = true;
          stockOutReturnRecordCreate({ ...formData, delivery_material: this.deliveryMaterial })
            .then((data) => {
              this.$message.success(this.$t("退库成功"));
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
